import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class PartyRelationship {
  constructor () {
    const base = axios.create({
      // using base customer api url for this, not promos
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.partyRelationship = base
  }

  async getPartyRelationships (party_id, offset = 0, limit = 200) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: `/party_relationship/${party_id}/${limit}/${offset}`,
      method: 'GET'
    })
  }

  async* genPartyRelationships (party_id, offset = 0, limit = 200, current = false, getAttributes = false) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    let page = 1
      
     while (true) {
      offset = (page * limit) - limit
  
      const res = await this.partyRelationship({
        url: `/party_relationship/${party_id}/${limit}/${offset}`,
        method: 'GET',
        params: {
          current: current,
          get_attributes: getAttributes
        }
      })

      if (!res.data || res.data.length === 0) {
        break
      }
    
      yield res.data
    
      if (res.data.length < limit) break
      page++
    }
  }

  async getMatch (party1, party2) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: `/party_relationship/related`,
      method: 'GET',
      params: {
        party_1_id: party1.party_1_id,
        party_1_type: party1.party_1_type,
        party_1_email: party1.party_1_email,
        party_2_id: party2.party_2_id,
        party_2_type: party2.party_2_type,
        party_2_email: party2.party_2_email
      }
    })
  }

  async getUpstreamRelationships (payload) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: `/party_relationship/upstream_search`,
      method: 'POST',
      data: payload
    })
  }

  async getDownstreamRelationships (data) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: '/party_relationship/downstream',
      method: 'GET',
      params: {
        party_id: data.party_id,
        party_type: data.party_type || null,
        party_email: data.party_email || null
      }
    })
  }

  async downstreamRelationshipSearch (payload) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: '/party_relationship/downstream_search',
      method: 'POST',
      data: payload
    })
  }
}

export default new PartyRelationship()