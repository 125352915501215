import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'

class PromoBillingParties {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.promoBillingParties = base
  }

  async getList(promoId, offset=0, limit=25, all=false, callback=null){
    await AuthToken.setAuth(this.promoBillingParties)

    const url = `/billing_parties/${promoId}`
    if (all) {
      return paginate(this.promoBillingParties, url, limit, offset, callback)
    }
    return this.promoBillingParties(
      {
        url: url,
        method: 'GET',
        params: {
          'offset': offset,
          'limit': limit
        }
      }
    )
  }

  async post(party)
  {
    await AuthToken.setAuth(this.promoBillingParties)

    return this.promoBillingParties(
      {
        url: `/billing_party`,
        method: 'POST',
        data: party
      }
    )
  }

  async put(promoId, partyId, updateParty)
  {
    await AuthToken.setAuth(this.promoBillingParties)

    return this.promoBillingParties(
      {
        url: `/billing_party/${promoId}/${partyId}`,
        method: 'PUT',
        data: updateParty
      }
    )
  }

  async delete(promoId, partyId)
  {
    await AuthToken.setAuth(this.promoBillingParties)

    return this.promoBillingParties(
      {
        url: `billing_party/${promoId}/${partyId}`,
        method: 'DELETE'
      }
    )
  }
}

export default new PromoBillingParties()