import { debounce } from 'lodash'

export const buildElasticQuery = {
  data () {
    return {
      expanded: [],
      queryParts: {
        must: []
      },
      search: '',
      showExpand: false
    }
  },
  watch: {
    search: {
      handler () {
        this.page = 1
        this.debounceBind()
        this.persistSearch()
      }
    }
  },
  created () {
    this.debounceBind = debounce(this.debounceBind, 500)
  },
  methods: {
    buildElasticQuery (index) {
      const tenantKey = this.getTenantKey(index)
      const query = {
        bool: {
          must: [
          ],
          filter: [
            { term: { [`${tenantKey}`]: this.$auth.tenant } }
          ]
        }
      }

      if (this.search?.length >= 1) {
        const search = this.search
        query.bool.must.push(
          {
            simple_query_string: {
              query: search,
              default_operator: 'AND',
              analyze_wildcard: true
            }
          }
        )
      }

      if (this.queryParts.must.length >= 1) {
        query.bool.must.push(...this.queryParts.must)
      }

      if (this.queryParts?.should?.length >= 1) {
        query.bool.should = []
        query.bool.should.push(this.queryParts.should)
      }

      if (this.queryParts?.must_not?.length >= 1) {
        query.bool.must_not = []
        query.bool.must_not.push(this.queryParts.must_not)
      }

      return query
    },
    getTenantKey (index) {
      if (index === 'CUSTOMER' || index === 'ITEM') {
        return 'tenant.keyword'
      }
      return 'audit.tenant.keyword'
    },
    buildHighlight (query) {
      const highlight = {
        order: 'score',
        pre_tags: ['<em>'],
        post_tags: ['</em>'],
        type: 'plain',
        fields: {
          '*': {}
        }
      }

      if (this.search.length >= 1) {
        query.body.highlight = highlight
        this.showExpand = true
      }
    },
    clearSearch () {
      this.search = ''
      this.showExpand = false
      sessionStorage.removeItem(`ipro_promo_${this.$options.name}`)
    },
    submitSearch (searchFunc) {
      return function () {
        searchFunc()
      }
    },
    debounceBind () {
      this.submitSearch()
    },
    persistSearch () {
      sessionStorage.setItem(`ipro_promo_${this.$options.name}`, this.search)
    }
  }
}
