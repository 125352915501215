<template>
  <v-card>
    <v-dialog
      :value="value"
      @click:outside="closeModal"
      width="800">
      <v-card>
        <v-data-table
          :items.sync="parties"
          :headers="headers"
          height="400"
          fixed-header
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [-1]}"
          :loading="loading"
          loading-text="Loading... Please wait"
          :sort-by="isGroupPartyType ? null : 'store_number'"
          dense>
          <template v-slot:top>
            <v-toolbar color='toolbar'>
              <v-toolbar-title>
                {{ title }}
              </v-toolbar-title>
              <v-spacer/>
              <v-btn 
                v-if="!addStores" 
                :disabled="excludeAllDisabled"
                class="white--text mx-2"
                color="green"
                @click="promptConfirm()">
                Exclude All
              </v-btn>
              <v-btn 
                v-if="isGroupPartyType" 
                :disabled="addAllDisabled"
                class="white--text mx-2"
                color="green"
                @click="addRegionGroup()">
                Add All
              </v-btn>
              <v-btn icon @click="closeModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top v-if="isGroupPartyType">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="isAddDisabled(item)"
                  icon
                  small
                  color="green"
                  @click="addParty(item)">
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <span>Add</span>
            </v-tooltip>
            <v-tooltip top v-else>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  small
                  @click="exclude(item)">
                  <v-icon :color="item.include_store ? 'green' : 'red'">
                    {{ (item.include_store) ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close-circle-outline'}}
                  </v-icon>
                </v-btn>
              </template>
              <span>Exclude</span>
            </v-tooltip>
          </template>

          <template v-slot:footer>
            <v-toolbar flat dense v-if="excluded !== null">
              <v-spacer/>
              <v-alert text dense :type="notification">
                {{exclusion}} {{(excluded) ? 'Excluded' : 'Already Excluded'}}
              </v-alert>
              <v-spacer/>
            </v-toolbar>
          </template>

        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="excludeAllDialog"
      justify="center"
      v-model="excludeAllDialog"
      persistent
      width="500">
      <v-card class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="orange">mdi-alert-circle-outline</v-icon>
        </v-row>
        <v-row justify="center" class="py-6">
          <h3>Exclude All Stores in Group</h3>
        </v-row>
        <v-row justify="center" class="pb-4 pt-2">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                small
                color="red"
                class="elevation-3 white--text"
                @click.stop="excludeAllDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cancel</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                small
                color="green"
                class="white--text elevation-3 ml-2"
                @click="excludeGroup()">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Confirm</span>
          </v-tooltip>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
// API
// import Es from '@/axios/es'
import PromoStore from '@/axios/promotion-store-endpoint'
import PartyRelationship from '@/axios/party-relationship-endpoint'
// mixins
import { buildElasticQuery } from '@/mixins/es_querybuilder'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// third party helpers
import { cloneDeep } from 'lodash'
export default {
  name: 'ViewParties',
  data () {
    return {
      updateOnClose: false,
      addedRegionGroup: false,
      excludeAllDialog: false,
      parties: [],
      exclusion: '',
      notification: '',
      excluded: null,
      serverItemsLength: 0,
      from: 0,
      baseHeaders: [
        { text: 'Group / Store', sortable: false, value: 'party_name', class: 'accent white--text' },
        { text: 'Type', sortable: false, value: 'party_type', class: 'accent white--text' },
      ]
    }
  },
  watch: {
    stores: {
      handler (newValue) {
        if (newValue) {
          this.getParties()
        }
      },
      deep: true
    }
  },
  mixins: [buildElasticQuery, displayAlert, userAccess, utils],
  props: {
    value: Boolean,
    promo: Object,
    party: Object,
    addStores: Boolean,
    stores: Array,
    readOnlyPromo: {
      type: Boolean,
      default: true
    },
    downstreamPartyIds: Array
  },
  created () {
    this.getParties()
  },
  computed: {
    title () {
      const name = this.party?.party_name || this.party.name
      const memberType = this.isRegionGroup ||
        (this.isDistributionCenter && this.$auth.tenant === 'awg') ? 'Ad Groups' : 'Stores'
      return `${name} ${memberType}`
    },
    firstHeader () {
      const storeNumber = { text: 'Store Number', value: 'store_number', sortable: false, class: 'accent white--text', align: 'center' }
      const spacer = { text: '', sortable: false, filterable: false, class: 'accent'}
      return this.isRegionGroup || this.isDistributionCenter ? spacer : storeNumber
    },
    headers () {
      const baseHeaders = [this.firstHeader, ...this.baseHeaders]
      const editHeaders = [
        ...baseHeaders,
        { text: (this.isGroupPartyType ? 'Add' : 'Included / Excluded'), align: 'center', value: 'actions', sortable: false, class: 'accent white--text' }
      ]
      if (this.readOnlyPromo) {
        return baseHeaders
      }
      if (this.isGroupPartyType) return editHeaders
      else return this.addStores ? baseHeaders : editHeaders
    },
    isDistributionCenter() {
      return (this.party?.party_type === 'DISTRIBUTION_CENTER')
        || (this.party?.party_type_name?.toLowerCase() === 'distribution center')
    },
    isRegionGroup () {
      return (this.party?.party_type === 'REGION_GROUP')
        || (this.party?.party_type_name?.toLowerCase() === 'region group')
    },
    partySearchType () {
      return this.isRegionGroup || (this.isDistributionCenter && this.$auth.tenant === 'awg') ? 'AD_GROUP' : 'STORE'
    },
    isGroupPartyType () {
      return ['REGION_GROUP', 'STORE_GROUP'].includes(this.party?.party_type)
        || ['region group', 'store group'].includes(this.party?.party_type_name?.toLowerCase())
    },
    addAllDisabled () {
      return this.parties.every(party => this.isAddDisabled(party))
    },
    excludeAllDisabled () {
      return this.parties.every(party => party.include_store === false)
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', this.updateOnClose)
    },
    addParty (party) {
      this.$emit('addParty', party)
      if (!this.updateOnClose) this.updateOnClose = true
      this.getParties()
    },
    addRegionGroup () {
      const newPartyIds = this.parties.flatMap(party => {
        return !this.isAddDisabled(party) ? party.party_id : []
      })
      this.$emit('addGroupMembers', this.party, newPartyIds)
      this.addedRegionGroup = true
      if (!this.updateOnClose) this.updateOnClose = true
    },
    storeIDs() {
      return this.stores.flatMap(s => s.store_id)
    },
    async getParties () {
      this.loading = true
      try {
        this.parties = await this.getMemberData()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async getMemberData() {
      // Most of this is ripping off the first half of the getMemberPartyId's function in utils. But changing it to provide a different output
      const partyId = this.party.party_id || this.party.store_id
      const isRegionGroup = (this.party.party_type === 'REGION_GROUP')
      || (this.party.party_type_name?.toLowerCase() === 'region group')
      const relParty = isRegionGroup ? 'to_party' : 'from_party'
      const relAttributes = isRegionGroup ? 'to_attributes' : 'from_attributes'
      const memberData = []

      const generator = PartyRelationship.genPartyRelationships(partyId, 0, 200, true, true)
      for await (const results of generator) {
        if (results?.length > 0) {
          const stores = results.flatMap(rel => {
            if ([this.partySearchType].includes(rel[relParty]?.party_type?.constant)) {
              let include = this.get_included(rel[relParty].id)
              const data = {
                attributes: rel.attributes[relAttributes],
                party_id: rel[relParty].id,
                party_name: rel[relParty].name,
                party_type: rel[relParty].party_type.constant,
                store_number: rel.attributes[relAttributes]?.attr_value,
                include_store: include,
                tenant: rel.tenant
              }
              return data
            }
            return []
          })
          memberData.push(...stores)
        }
      }
      if (this.limitAccessByRelatedStores && isRegionGroup) {
        return memberData.filter(data => this.userRelatedPartyIds.includes(data.party_id))
      }
      return memberData
    },
    isAddDisabled (party) {
      return this.addedRegionGroup
        || this.stores.some(store => store.store_id === party.party_id)
        || this.downstreamPartyIds.includes(party.party_id)
    },
    get_included(storeID)
    {
      // If the store isn't in the group list, then return true (included)
      if (!this.storeIDs().includes(storeID))
        return true
        
      // Return the included/excluded value 
      return this.stores.filter(s => s.store_id == storeID)[0].include_store
    },
    /*
    async getParties () {
      const query = this.buildPartySearchQuery()
      const postObj = {
        body: {
          from: this.from,
          size: this.pageSize,
          query: query
        }
      }
      let parties = []
      let total = 0
      try {
        const res = await Es.post('CUSTOMER', '/_search?rest_total_hits_as_int', postObj)
        if (res?.hits?.hits?.length > 0) {
          parties = res.hits.hits.map(result => {
            const party = result._source
            const { attrs } = party
            if (attrs?.length > 0) {
              const attr = attrs.find(a => {
                return Object.keys(a).includes('WHOLESALER_STORE_NUMBER')
              })
              if (attr) party.store_number = attr.WHOLESALER_STORE_NUMBER
            }
            return party
          })
          total = res.hits.total
        }
        this.parties = parties
        this.serverItemsLength = total
      } catch (err) {
        this.handleError(err)
      }
    },
    */
    buildPartySearchQuery () {
      const partyId = this.party.store_id || this.party.id
      const query = cloneDeep(this.buildElasticQuery('CUSTOMER'))
      let filterQueries = []
      if (this.isRegionGroup) {
        const partyIds = this.party.related_party.flatMap(rel => {
          // only return ad group party ids
          return (rel.from_type_constant === 'HAS_MEMBER')
            ? rel.related_party_id
            : []
        })
        filterQueries = [
          { terms: {'id.keyword': partyIds} }
        ]
      } else {
        filterQueries = [
          { term: {'related_party.related_party_id.keyword': partyId} },
          { term: {'party_type.constant.keyword': this.searchPartyType }}
        ]
      }
      query.bool.filter.push(...filterQueries)
      return query
    },
    async exclude (store) {
      this.exclusion = store.party_name
      let method = 'POST'
      let refStore = null

      if (store.include_store === false) {
        this.excludeFailure("Already excluded")
        return
      }

      if (this.stores.some(s => s.store_id === store.party_id)) {
        method = 'PUT'
        refStore = this.stores.find(s => s.store_id === store.party_id)
      }

      const payload = {
        promo_id: this.promo.id,
        store_id: store.party_id,
        include_store: false,
        enforce_compliance: refStore ? refStore.enforce_compliance : false
      }
      
      if (method === 'PUT') {
        await PromoStore.put(refStore.id, payload)
        .then(() => {
          this.excludeSuccess()
        }).catch(err => {
          this.excludeFailure(err)
        })
      } else {
        await PromoStore.post(payload)
        .then(() => {
          this.excludeSuccess()
        }).catch(err => {
          this.excludeFailure(err)
        })
      }
    },
    promptConfirm () {
      this.excludeAllDialog = true
    },
    async excludeGroup () {
      const excludeIDs = this.parties.flatMap(party => {
        return party.include_store === true ? party.party_id : []
      })
      // Figure out if we need to update existing stores on the promo, or add new stores
      const toExclude = excludeIDs.map(partyID => {
        let method = 'POST'
        let store = null
        if (this.stores.some(store => store.store_id === partyID)) {
          method = 'PUT'
          store = this.stores.find(store => store.store_id === partyID)
        }
        return {
          party_id: partyID,
          method: method,
          store: store
        }
      })

      // Prepare all the API calls
      const promises = toExclude.map(party => {
        const payload = {
          promo_id: this.promo.id,
          store_id: party.party_id,
          include_store: false,
          enforce_compliance: party.store ? party.store.enforce_compliance : false
        }
        if (party.method == 'PUT') {
          return PromoStore.put(party.store.id, payload)
        } else {
          return PromoStore.post(payload)
        }
      })

      try {
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
        this.excludeSuccess()
        this.excludeAllDialog = false
        this.$emit("removeGroup", this.party, true)
      } catch(err) {
        this.excludeFailure(err)
      }
    },
    excludeSuccess () {
      this.getParties()
        this.$emit('initStores')
        this.notification = 'success'
        this.excluded = true
        setTimeout(() => {
          this.excluded = null
        }, 2000);
    },
    excludeFailure (err) {
      console.log(err)
      this.notification = 'error'
      this.excluded = false
      setTimeout(() => {
        this.excluded = null
      }, 2000);
    }
  }
}
</script>
