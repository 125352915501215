import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class PartyAttributeValue {
  constructor () {
    const base = axios.create({
      // using base customer api url for this, not promos
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.partyAttributeValue = base
  }

  async getAttributeValuesByParty (party_id, limit = 100, offset = 0) {
    await AuthToken.setAuth(this.partyAttributeValue, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyAttributeValue({
      url: `/party_attribute_value/${party_id}/${limit}/${offset}`,
      method: 'GET'
    })
  }
}

export default new PartyAttributeValue()