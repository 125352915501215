import Vue from 'vue'
import Vuex from 'vuex'
import { getInstance } from '@/auth/auth0-plugin'

// API
import kvApi from '@/axios/key-values-endpoint'
import PromoCategory from '@/axios/promo-category-endpoint'
import PartyCategoryRelationship from '@/axios/party-category-relationship-endpoints'
import Party from '@/axios/party-endpoint'
import Items from '@/axios/items-endpoint'

Vue.use(Vuex)

const state = {
  BillingModes: [],
  BillingSplitTypes: [],
  PromotionTypes: [],
  ResponsibleParties: [],
  PromoClassifications: [],
  PromoCategories: [],
  userRelatedParties: [],
  categoryManagers: [],
  adGroups: [],
  distributionCenters: [],
  promoStatuses: [],
  userPromoCategoryIds: [],
  readOnlyCategories: ['AMAP'],
  vendorHiddenCategories: [
    'AMAP',
    'Scan Fee',
    'Accrual Ad Billback',
    'TPR Non Allowance',
    'TPR Scan',
    'When You Buy',
    'Ad Scan Required Retail',
    'Hot Buy Scan',
    'Hot Buy Billback'
  ],
  pceHiddenCategories: [
    'Limited Billback',
    'Accrual Ad Billback',
    'Ad Billback - Meat'
  ],
}

const mutations = {
  commitBillingModes (state, payload) {
    state.BillingModes = payload
  },
  commitBillingSplitTypes (state, payload) {
    state.BillingSplitTypes = payload
  },
  commitPromoTypes (state, payload) {
    state.PromotionTypes = payload
  },
  commitResponsibleParties (state, payload) {
    state.ResponsibleParties = payload
  },
  commitPromoClassifications (state, payload) {
    state.PromoClassifications = payload
  },
  commitPromoCategories (state, payload) {
    state.PromoCategories = payload
  },
  commitUserRelatedParties (state, payload) {
    state.userRelatedParties = payload
  },
  commitCategoryManagers (state, payload) {
    state.categoryManagers = payload
  },
  commitPromoStatusValues (state, payload) {
    state.promoStatuses = payload
  },
  commitUserPromoCategoryIds (state, payload) {
    state.userPromoCategoryIds = payload
  },
  commitAdGroups (state, payload) {
    state.adGroups = payload
  },
  commitDistributionCenters (state, payload) {
    state.distributionCenters = payload
  }
}

const actions = {
  async initParties (context) {
    const authService = getInstance()
    const actions = [
      'getAdGroups'
    ]
    if (authService.tenant === 'pricechopper') {
      actions.push('getCategoryManagers')
    }
    if (authService.tenant === 'jbg') {
      actions.push('getDistributionCenters')
    }
    const promises = actions.map(action => {
      return context.dispatch(action)
    })
    try {
      const results = await Promise.allSettled(promises)
      const rejected = results.filter(r => r.status === 'rejected')
      if (rejected.length > 0) throw rejected
    } catch (err) {
      console.error(err)
    }
  },
  async getPromoKeyValues (context) {
    const kvActions = [
      'getBillingModes',
      'getBillingSplitTypes',
      'getPromotionTypes',
      'getPromotionClassifications',
      'getPromoStatusValues'
    ]
    try {
      const promises = kvActions.map(action => {
        return context.dispatch(action)
      })
      const results = await Promise.allSettled(promises)
      const rejected = results.filter(r => r.status === 'rejected')
      if (rejected.length > 0) throw rejected
    } catch (err) {
      console.error(err)
    }
  },
  async getBillingModes (context) {
    const billing_modes = context.getters.billing_modes
    if (billing_modes.length === 0) {
      return kvApi.keyValues.getList('BILLING_MODE', 50, 0)
				.then((response) => {
          context.commit('commitBillingModes', response.data)
				}).catch(err => {
          throw err
        })
    }
  },
  async getBillingSplitTypes (context) {
    const billing_split = context.getters.billing_split
    if (billing_split.length === 0) {
      return kvApi.keyValues.getList('BILLING_SPLIT_TYPE', 50, 0)
				.then((response) => {
          context.commit('commitBillingSplitTypes', response.data)
				}).catch(err => {
          throw err
        })
    }
  },
  async getPromotionTypes (context) {
    const promo_types = context.getters.promo_types
    if (promo_types.length === 0) {
      return kvApi.keyValues.getList('PROMO_TYPE', 50, 0)
				.then((response) => {
          if (response.data.length > 0) {
            response.data.sort((a, b) => (a.display_order - b.display_order))
          }
          context.commit('commitPromoTypes', response.data)
				}).catch(err => {
          throw err
        })
    }
  },
  async getPromotionClassifications (context) {
    const promo_classes = context.getters.promo_classes
    if (promo_classes.length === 0) {
      return kvApi.keyValues.getList('CLASSIFICATION', 50, 0)
				.then((response) => {
          context.commit('commitPromoClassifications', response.data)
				}).catch(err => {
          throw err
        })
    }
  },
  async getPromoStatusValues (context) {
    const promo_statuses = context.getters.promo_statuses
    if (promo_statuses.length === 0) {
      return kvApi.keyValues.getList('STATUS', 50, 0).then(response => {
        if (response.data.length > 0) {
          response.data.sort((a, b) => (a.display_order - b.display_order))
        }
        context.commit('commitPromoStatusValues', response.data)
      }).catch(err => {
        throw err
      })
    }
  },
  async getPromoCategories (context) {
    const promo_categories = context.getters.promo_categories
    if (promo_categories.length === 0) {
      return PromoCategory.getList()
				.then((response) => {
          if (response.data.length > 0) {
            response.data = response.data.filter(c => {
              return c.name.toLowerCase().includes('lump sum') === false
            }).sort((a, b) => (a.display_order - b.display_order))
          }
          context.commit('commitPromoCategories', response.data)
        }).catch(err => {
          throw err
        })
    }
  },
  async getResponsibleParties(context) {
    let parties = []
    if (context.getters.responsible_parties?.length > 0) return
    try {
      const res = await Party.getWholesalers()
      parties = res?.data || []
      return parties.length > 0 ? context.dispatch('getSourceRels', parties) : []
    } catch (err) {
      console.error(err)
    }
  },
  async getSourceRels (context, parties) {
    if (parties.length === 1) {
      const party = parties[0]
      const res = await Items.getSourceRelsByPartyId(party.id)
      party.sources = res?.data || []
      return context.commit('commitResponsibleParties', parties)
    }
    const res = await Promise.allSettled(parties.map(party => {
      return Items.getSourceRelsByPartyId(party.id)
    }))
    const mapped = parties.map((party, i) => {
      const result = res[i]
      if (result?.status === 'rejected') {
        throw result.reason
      } else {
        party.sources = result?.value?.data || []
      }
      return party
    })
    return context.commit('commitResponsibleParties', mapped)
  },
  async getUserRelatedParties (context) {
    const userParties = context.getters.userRelatedParties
    if (userParties.length > 0) return

    const authService = getInstance()
    const { email } = authService.user

    let relatedParties = []
    try {
      const res = await Party.getUserProfile(email)
      if (res?.data?.length > 0) {
        relatedParties = res.data
      }
    } catch (err) {
      console.error(err)
    }
    return context.commit('commitUserRelatedParties', relatedParties)
  },
  async getCategoryManagers (context) {
    let categoryManagers = []
    try {
      const res = await Party.getCategoryManagers()
      if (res?.data?.length > 0) {
        categoryManagers = res.data.sort((a, b) => a.name.localeCompare(b.name))
      }
    } catch (err) {
      console.error(err)
    }
    return context.commit('commitCategoryManagers', categoryManagers)
  },
  async getUserCategories (context) {
    const relatedParties = context.getters.userRelatedParties
    if (relatedParties.length > 0) {
      const vendorPartyIds = relatedParties.flatMap(party => {
        return (party?.party_type_constant === 'VENDOR') ? party.id : []
      })
      const promises = vendorPartyIds.map(partyId => {
        return PartyCategoryRelationship.getRelationshipsByParty(partyId)
      })
      try {
        const results = await Promise.allSettled(promises)
        const rejected = results.filter(r => r.status === 'rejected')
        if (rejected.length > 0) throw rejected

        const categoryIds = results.flatMap(r => {
          if (r.status === 'fulfilled' && r.value?.data?.length > 0) {
            return r.value.data.map(rel => rel.promo_category_id)
          }
          return []
        })
        context.commit('commitUserPromoCategoryIds', categoryIds)
      } catch (err) {
        console.error(err)
      }
    }
  },
  async getAdGroups (context) {
    if (context.getters.adGroups.length > 0) return
    let adGroups = []
    try {
      const res = await Party.getAdGroups()
      adGroups = res?.data || []
      return context.commit('commitAdGroups', adGroups)
    } catch (err) {
      console.error(err)
    }
  },
  async getDistributionCenters (context) {
    if (context.getters.distributionCenters.length > 0) return
    let distributionCenters = []
    try {
      const res = await Party.getDistributionCenters()
      if (res?.data?.length > 0) {
        distributionCenters = res.data.sort((a, b) => a.name.localeCompare(b.name))
      }
      return context.commit('commitDistributionCenters', distributionCenters)
    } catch (err) {
      console.error(err)
    }
  },
}

const getters = {
  billing_modes (state) {
    return state.BillingModes
  },
  billing_split (state) {
    return state.BillingSplitTypes
  },
  promo_types (state) {
    return state.PromotionTypes
  },
  promo_classes (state) {
    return state.PromoClassifications
  },
  promo_categories (state) {
    return state.PromoCategories
  },
  promo_statuses (state) {
    return state.promoStatuses
  },
  responsible_parties (state) {
    return state.ResponsibleParties
  },
  sources (state) {
    return state.ResponsibleParties.flatMap(party => party?.sources || [])
  },
  getSourceName: (state, getters) => source_id => {
    const match = getters.sources.find(s => s?.source?.source_id === source_id)
    return match?.source?.source_name || source_id
  },
  userRelatedParties (state) {
    return state.userRelatedParties
  },
  userRelatedPartyIds (state) {
    if (state.userRelatedParties.length > 0) {
      return state.userRelatedParties.map(party => party.id)
    }
    return []
  },
  userPromoCategoryIds (state) {
    return state.userPromoCategoryIds
  },
  adGroups (state) {
    return state.adGroups
  },
  categoryManagers () {
    return state.categoryManagers
  },
  distributionCenters (state) {
    return state.distributionCenters
  }
}

export const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations
})
