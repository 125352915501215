import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'

class PromoStore {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.promoStore = base
  }
  
  handleSuccess (response) {
    return response
  }

  async post (data) {
    await AuthToken.setAuth(this.promoStore)

    return this.promoStore({
      url: `/promo_store`,
      method: 'POST',
      data: data
    })
  }

  async put (promo_store_id, data) {
    await AuthToken.setAuth(this.promoStore)

    return this.promoStore({
      url: `/promo_store/${promo_store_id}`,
      method: 'PUT',
      data: data
    })
  }
  
  async getStores (promo_id, limit = 250, offset = 0, paging = true) {
    await AuthToken.setAuth(this.promoStore)
    const url = `/promo_stores/${promo_id}`

    if (paging) {
      return paginate(this.promoStore, url, limit, offset)
    }
    return this.promoStore({
      url,
      method: 'GET',
      params: {
        limit,
        offset
      }
    })
  }

  async get (promo_store_id) {
    await AuthToken.setAuth(this.promoStore)
    return this.promoStore({
      url: `/promo_store/${promo_store_id}`,
      method: 'GET'
    })
  }

  async delete (promo_store_id) {
    await AuthToken.setAuth(this.promoStore)
    return this.promoStore({
      url: `/promo_store/${promo_store_id}`,
      method: 'DELETE'
    })
  }
}

export default new PromoStore()