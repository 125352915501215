import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class ApiBase {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })

    this.api = base
  }
}

class Keys extends ApiBase {
  constructor (api) {
    super(api)
  }

  handleSuccess (response) {
    return response
  }

  async getList (keyId, limit, offset) {
    await AuthToken.setAuth(this.api)

    return this.api({
      url: `${keyId}`,
      method: 'GET',
      params: {
        LIMIT: limit,
        OFFSET: offset
      }
    })
  }

  get () {
    return this.api({
      url: '/key',
      method: 'GET'
    })
  }

//   post (data) {
//     return this.api({
//       url: 'v1/key',
//       method: 'POST',
//       data: data
//     })
//   }

//   put (id, data) {
//     return this.api({
//       url: `v1/key/${id}`,
//       method: 'PUT',
//       data: data
//     })
//   }
}

class KeyValues extends ApiBase {

  handleSuccess (response) {
    return response
  }

  async getList (keyId, limit, offset) {
    await AuthToken.setAuth(this.api)

    return this.api({
      url: `key_values/${keyId}`,
      method: 'GET',
      params: {
        limit: limit,
        offset: offset
      }
    })
  }

//   post (data) {
//     return this.api({
//       url: 'v1/key_value',
//       method: 'POST',
//       data: data
//     })
//   }

//   get (uuid) {
//     return this.api({
//       url: `v1/key_value/${uuid}`,
//       method: 'GET'
//     })
//   }

//   getList (keyId, limit, offset) {
//     return this.api({
//       url: `v1/key_value/${keyId}/${limit}/${offset}`,
//       method: 'GET'
//     })
//   }

//   put (uuid, data) {
//     return this.api({
//       url: `v1/key_value/${uuid}`,
//       method: 'PUT',
//       data: data
//     })
//   }
// }
}
export default {
  keys: new Keys(),
  keyValues: new KeyValues()
}