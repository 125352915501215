var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fff" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pl-0",
              staticStyle: { height: "64px" },
              attrs: { cols: "3" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Filter items",
                  "prepend-inner-icon": "mdi-filter-outline",
                  outlined: "",
                  dense: "",
                  "background-color": "#fff",
                  clearable: ""
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.id && !_vm.readOnlyPromo,
                      expression: "id && !readOnlyPromo"
                    }
                  ],
                  staticClass: "ml-2",
                  attrs: {
                    id: "newGroupButton",
                    small: "",
                    color: "primary",
                    dark: ""
                  },
                  on: { click: _vm.createNewGroup }
                },
                [_vm._v(" New Group ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        attrs: {
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          expanded: _vm.expanded,
          items: _vm.items,
          "items-per-page": -1,
          "footer-props": { "items-per-page-options": [-1] },
          color: "#fff",
          "loading-text": "Loading... Please wait",
          "no-data-text": _vm.noDataText,
          "fixed-header": "",
          dense: "",
          "group-by": "promo_item_group.id",
          search: _vm.filter
        },
        on: {
          "update:items": function($event) {
            _vm.items = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "no-data",
              fn: function() {
                return [
                  !_vm.loading && _vm.items.length === 0
                    ? _c("v-alert", {
                        staticClass: "mt-4",
                        attrs: { outlined: "", text: "", color: "orange" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-2 pb-1",
                                      attrs: { color: "orange" }
                                    },
                                    [_vm._v(" mdi-alert-circle-outline ")]
                                  ),
                                  _vm.itemGroupList.length === 0
                                    ? _c("span", [
                                        _vm._v(
                                          " Please create an item group to add items to this promotion. "
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " Valid promotion requires at least one item added to promotion. "
                                        )
                                      ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          355374527
                        )
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "header.variance",
              fn: function(ref) {
                var header = ref.header
                return [
                  _vm.promo.enforce_compliance
                    ? _c("span", [_vm._v(_vm._s(header.text))])
                    : _vm._e()
                ]
              }
            },
            {
              key: "group.header",
              fn: function(ref) {
                var group = ref.group
                var items = ref.items
                var headers = ref.headers
                return [
                  _c("td", { attrs: { colspan: "3" } }, [
                    _vm._v(_vm._s(items[0].promo_item_group.name))
                  ]),
                  _c("td", { attrs: { colspan: headers.length - 5 } }, [
                    _c("em", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.groupOverridingPromo(items[0].promo_item_group)
                              ? "Group values are overriding promo values"
                              : "Values set by promo"
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "group-actions-container" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.editGroup(group)
                              }
                            }
                          },
                          [
                            !_vm.readOnlyPromo
                              ? _c("v-icon", [_vm._v("mdi-pencil-outline")])
                              : _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c("v-icon", _vm._g({}, on), [
                                                _vm._v(
                                                  "mdi-information-variant-circle-outline"
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_vm._v(" View Group Details ")]
                                )
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.readOnlyPromo,
                                expression: "!readOnlyPromo"
                              }
                            ],
                            staticClass: "ml-1",
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deletedPromoItemGroup(
                                  group,
                                  items[0].promo_item_group.name
                                )
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-trash-can-outline")])],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              }
            },
            {
              key: "item.item.qty_per_case",
              fn: function(ref) {
                var item = ref.item
                return [
                  !_vm.usePromoItemQtyPerCase
                    ? _c("span", [
                        _c("span", [
                          _vm._v(" " + _vm._s(item.item.qty_per_case) + " ")
                        ])
                      ])
                    : _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "close-on-click": false,
                            bottom: "",
                            right: "",
                            absolute: "",
                            "nudge-top": "-12px",
                            "nudge-left": "16px",
                            "offset-y": "",
                            "max-width": "300px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "edit-menu-container",
                                            style: !_vm.readOnlyPromo
                                              ? "cursor: pointer"
                                              : "cursor: default",
                                            on: {
                                              click: function($event) {
                                                !_vm.readOnlyPromo
                                                  ? _vm.openQtyPerCase(item)
                                                  : null
                                              }
                                            }
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        !_vm.readOnlyPromo ? on : null
                                      ),
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.promo_item_qty_per_case
                                                ? item.promo_item_qty_per_case
                                                : item.item.qty_per_case
                                            )
                                          )
                                        ]),
                                        !_vm.readOnlyPromo
                                          ? _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("mdi-pencil")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.editingQtyPerCase,
                            callback: function($$v) {
                              _vm.editingQtyPerCase = $$v
                            },
                            expression: "editingQtyPerCase"
                          }
                        },
                        [
                          _vm.editItem && _vm.editItem.id === item.id
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "pt-2 px-4",
                                  attrs: { tile: "", flat: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "8px" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle-2" },
                                        [_vm._v("Edit Qty Per Case")]
                                      )
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    ref: "qty_per_case",
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      rules: [
                                        _vm.isValidQuantity,
                                        function(v) {
                                          return (
                                            !(v < 0) ||
                                            "Cannot have negative value."
                                          )
                                        }
                                      ],
                                      autofocus: "",
                                      type: "number",
                                      "hide-spin-buttons": ""
                                    },
                                    model: {
                                      value: _vm.editItem.qty_per_case,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editItem,
                                          "qty_per_case",
                                          $$v
                                        )
                                      },
                                      expression: "editItem.qty_per_case"
                                    }
                                  }),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pt-0 px-0" },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", small: "" },
                                          on: { click: _vm.cancel }
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "primary",
                                            loading: _vm.savingItem,
                                            disabled:
                                              _vm.savingItem ||
                                              _vm.applyingAll ||
                                              (_vm.editItem &&
                                                _vm.editItem.qty_per_case < 0)
                                          },
                                          on: { click: _vm.save }
                                        },
                                        [_vm._v(" Save ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "accent",
                                            loading: _vm.applyingAll,
                                            disabled:
                                              _vm.applyingAll ||
                                              _vm.savingItem ||
                                              (_vm.editItem &&
                                                _vm.editItem.qty_per_case < 0)
                                          },
                                          on: { click: _vm.openApplyAllDialog }
                                        },
                                        [_vm._v(" Apply All ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                ]
              }
            },
            {
              key: "item.item.source_name",
              fn: function(ref) {
                var item = ref.item
                return [
                  ["DISCONTINUED ITEM", "Inactive"].includes(item.item.status)
                    ? _c(
                        "v-tooltip",
                        {
                          staticClass: "mx-auto",
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "pb-1 ml-n8",
                                          attrs: {
                                            icon: "",
                                            small: "",
                                            color: "warning"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "mx-auto" },
                                          [_vm._v("mdi-alert")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Item has been Discontinued")])]
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(item.item.source_name) + " ")
                ]
              }
            },
            {
              key: "item.variance",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && _vm.promo.enforce_compliance
                    ? _c("span", [
                        _vm._v(
                          " " + _vm._s(item.variance ? item.variance : "") + " "
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.buy_item",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && _vm.promo_type_fields.includes("buy_item")
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.promo_item_group
                                ? item.promo_item_group.buy_item
                                : ""
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.get_item",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && _vm.promo_type_fields.includes("get_item")
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.promo_item_group
                                ? item.promo_item_group.get_item
                                : ""
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.qty",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && _vm.promo_type_fields.includes("qty")
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.promo_item_group
                                ? item.promo_item_group.qty
                                : ""
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.cost",
              fn: function(ref) {
                var item = ref.item
                return [
                  !_vm.usePromoItemCost
                    ? _c(
                        "span",
                        [
                          item.item.future_cost == item.item.cost &&
                          item.item.future_cost_date <= _vm.promo.end_dt
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "primary--text font-weight-bold"
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.item.cost) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(
                                    " This item has a cost change on " +
                                      _vm._s(
                                        _vm.$config.formatDate(
                                          item.item.future_cost_date
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " The new cost of " +
                                      _vm._s(item.item.cost) +
                                      " is being used on this promotion "
                                  )
                                ]
                              )
                            : _c("span", [
                                _vm._v(" " + _vm._s(item.item.cost) + " ")
                              ])
                        ],
                        1
                      )
                    : _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "close-on-click": false,
                            bottom: "",
                            right: "",
                            absolute: "",
                            "nudge-top": "-12px",
                            "nudge-left": "16px",
                            "offset-y": "",
                            "max-width": "300px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "edit-menu-container",
                                            style: !_vm.readOnlyPromo
                                              ? "cursor: pointer"
                                              : "cursor: default",
                                            on: {
                                              click: function($event) {
                                                !_vm.readOnlyPromo
                                                  ? _vm.open(item, "cost")
                                                  : null
                                              }
                                            }
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        !_vm.readOnlyPromo ? on : null
                                      ),
                                      [
                                        item.item.future_cost ==
                                          item.item.cost &&
                                        item.item.future_cost_date <=
                                          _vm.promo.end_dt
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "primary--text font-weight-bold"
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.cost
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(
                                                  " This item has a cost change on " +
                                                    _vm._s(
                                                      _vm.$config.formatDate(
                                                        item.item
                                                          .future_cost_date
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " The new cost of " +
                                                    _vm._s(item.cost) +
                                                    " is being used on this promotion "
                                                )
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                " " + _vm._s(item.cost) + " "
                                              )
                                            ]),
                                        !_vm.readOnlyPromo
                                          ? _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("mdi-pencil")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.editingCost,
                            callback: function($$v) {
                              _vm.editingCost = $$v
                            },
                            expression: "editingCost"
                          }
                        },
                        [
                          _vm.editItem && _vm.editItem.id === item.id
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "pt-2 px-4",
                                  attrs: { tile: "", flat: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "8px" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle-2" },
                                        [_vm._v("Edit Item Cost")]
                                      )
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    ref: "cost",
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      rules: [
                                        _vm.isValidPrice,
                                        function(v) {
                                          return (
                                            !(v < 0) ||
                                            "Cannot have negative value."
                                          )
                                        }
                                      ],
                                      autofocus: "",
                                      type: "number",
                                      "hide-spin-buttons": "",
                                      prefix: "$"
                                    },
                                    model: {
                                      value: _vm.editItem.cost,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editItem, "cost", $$v)
                                      },
                                      expression: "editItem.cost"
                                    }
                                  }),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pt-0 px-0" },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", small: "" },
                                          on: { click: _vm.cancel }
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "primary",
                                            loading: _vm.savingItem,
                                            disabled:
                                              _vm.savingItem ||
                                              _vm.applyingAll ||
                                              (_vm.editItem &&
                                                _vm.editItem.cost < 0)
                                          },
                                          on: { click: _vm.save }
                                        },
                                        [_vm._v(" Save ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "accent",
                                            loading: _vm.applyingAll,
                                            disabled:
                                              _vm.applyingAll ||
                                              _vm.savingItem ||
                                              (_vm.editItem &&
                                                _vm.editItem.cost < 0)
                                          },
                                          on: { click: _vm.openApplyAllDialog }
                                        },
                                        [_vm._v(" Apply All ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                ]
              }
            },
            {
              key: "item.retail_price",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "close-on-click": false,
                        bottom: "",
                        right: "",
                        absolute: "",
                        "nudge-top": "-12px",
                        "nudge-left": "16px",
                        "offset-y": "",
                        "max-width": "300px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "edit-menu-container",
                                        style: !_vm.readOnlyPromo
                                          ? "cursor: pointer"
                                          : "cursor: default",
                                        on: {
                                          click: function($event) {
                                            !_vm.readOnlyPromo
                                              ? _vm.open(item, "retail_price")
                                              : null
                                          }
                                        }
                                      },
                                      "div",
                                      attrs,
                                      false
                                    ),
                                    !_vm.readOnlyPromo ? on : null
                                  ),
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.retail_price))
                                    ]),
                                    !_vm.readOnlyPromo
                                      ? _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-pencil")
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.editingRetailPrice,
                        callback: function($$v) {
                          _vm.editingRetailPrice = $$v
                        },
                        expression: "editingRetailPrice"
                      }
                    },
                    [
                      _vm.editItem && _vm.editItem.id === item.id
                        ? _c(
                            "v-card",
                            {
                              staticClass: "pt-2 px-4",
                              attrs: { tile: "", flat: "" }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "8px" } },
                                [
                                  _c("span", { staticClass: "subtitle-2" }, [
                                    _vm._v("Edit Regular Retail")
                                  ])
                                ]
                              ),
                              _c("v-text-field", {
                                ref: "retail_price",
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  rules: [
                                    _vm.isValidPrice,
                                    function(v) {
                                      return (
                                        !(v < 0) ||
                                        "Cannot have negative value."
                                      )
                                    }
                                  ],
                                  autofocus: "",
                                  type: "number",
                                  "hide-spin-buttons": "",
                                  prefix: "$"
                                },
                                model: {
                                  value: _vm.editItem.retail_price,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editItem, "retail_price", $$v)
                                  },
                                  expression: "editItem.retail_price"
                                }
                              }),
                              _c(
                                "v-card-actions",
                                { staticClass: "pt-0 px-0" },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", small: "" },
                                      on: { click: _vm.cancel }
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        color: "primary",
                                        loading: _vm.savingItem,
                                        disabled:
                                          _vm.savingItem ||
                                          _vm.applyingAll ||
                                          (_vm.editItem &&
                                            _vm.editItem.retail_price < 0)
                                      },
                                      on: { click: _vm.save }
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        color: "accent",
                                        loading: _vm.applyingAll,
                                        disabled:
                                          _vm.applyingAll ||
                                          _vm.savingItem ||
                                          (_vm.editItem &&
                                            _vm.editItem.retail_price < 0)
                                      },
                                      on: { click: _vm.openApplyAllDialog }
                                    },
                                    [_vm._v(" Apply All ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.billback_allowance",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.billback_allowance != null && item.accrual_amt
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "open-on-hover": "",
                            "offset-y": "",
                            top: "",
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          "margin-right": "4px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.billback_allowance) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { small: "", icon: "" }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "ma-0 pa-0" },
                                          [_vm._v("mdi-information-outline")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-subtitle", [
                                            _vm._v("Allowance Amount")
                                          ]),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getFormattedAllowance(
                                                    item.promo_item_group
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-subtitle", [
                                            _vm._v("Allowance Type")
                                          ]),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.promo_item_group
                                                    .allowance_amt_type
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-subtitle", [
                                            _vm._v("Accrual Amount")
                                          ]),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  "$" +
                                                    _vm.$config.formatCurrency(
                                                      item.accrual_amt
                                                    )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c("span", [_vm._v(_vm._s(item.billback_allowance))])
                ]
              }
            },
            {
              key: "item.amap_allowance",
              fn: function(ref) {
                var item = ref.item
                return [
                  !_vm.isAMAP && Array.isArray(item.item.amap_allowances)
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "nudge-left": 250,
                            "offset-y": "",
                            "open-on-hover": "",
                            top: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass: "primary--text",
                                          staticStyle: { cursor: "pointer" }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.amap_allowance_total) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "pl-6 primary--text" },
                                [_vm._v(" Active AMAP Allowances ")]
                              ),
                              _c(
                                "v-list",
                                { staticClass: "pt-0" },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c(
                                            "v-list-item-subtitle",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(" Promo Name ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c(
                                            "v-list-item-subtitle",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(" Promo Number ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "2" } },
                                        [
                                          _c(
                                            "v-list-item-subtitle",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(" Amount ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(item.item.amap_allowances, function(
                                    amap
                                  ) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: amap.promo_id,
                                        attrs: { dense: "", link: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToPromo(amap.promo_id)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "5" } },
                                          [
                                            _c("v-list-item-subtitle", [
                                              _vm._v(_vm._s(amap.promo_name))
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "5" } },
                                          [
                                            _c("v-list-item-subtitle", [
                                              _vm._v(_vm._s(amap.promo_number))
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.$config.formatCurrency(
                                                      amap.amap_allowance
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c("span", [_vm._v(_vm._s(item.amap_allowance))])
                ]
              }
            },
            {
              key: "item.net_unit_cost",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.has_active_allowances
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "nudge-left": 250,
                            "offset-y": "",
                            "open-on-hover": "",
                            top: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass: "primary--text",
                                          staticStyle: { cursor: "pointer" }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.net_unit_cost) + " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("ActiveAllowances", {
                            attrs: {
                              item: item.item,
                              allowances: item.item.filtered_allowances
                            },
                            on: { goToPromo: _vm.goToPromo }
                          })
                        ],
                        1
                      )
                    : _c("span", [_vm._v(_vm._s(item.net_unit_cost))])
                ]
              }
            },
            {
              key: "item.related",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.item.has_item_group
                    ? _c(
                        "v-tooltip",
                        {
                          staticClass: "mx-auto",
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "px-0 mx-0",
                                          attrs: {
                                            icon: "",
                                            small: "",
                                            color: "#1976d2"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.viewRelatedItems(item)
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "mx-auto" },
                                          [_vm._v("mdi-view-list")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Related Items")])]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  !_vm.readOnlyPromo
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            icon: "",
                                            small: "",
                                            color: "red"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.removeItem(item)
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-trash-can-outline")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Remove")])]
                      )
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm.addItems
        ? _c("ItemSearchModal", {
            attrs: {
              itemGroup: _vm.itemGroup,
              panel_height: _vm.panel_height,
              promo: _vm.promo,
              promo_type_fields: _vm.promo_type_fields,
              promo_type_constant: _vm.promo_type_constant,
              promoItems: _vm.promoItems,
              item_sources: _vm.item_sources,
              vmc_sources: _vm.vmc_sources,
              readOnlyPromo: _vm.readOnlyPromo,
              vendorIds: _vm.vendorIds,
              isAccrualPromo: _vm.isAccrualPromo
            },
            on: {
              close: _vm.closeItemsModal,
              updateHistory: function($event) {
                return _vm.$emit("updateHistory")
              }
            },
            model: {
              value: _vm.addItems,
              callback: function($$v) {
                _vm.addItems = $$v
              },
              expression: "addItems"
            }
          })
        : _vm._e(),
      _vm.showRelatedItemsModal
        ? _c("RelatedItemsModal", {
            attrs: {
              promoItem: _vm.promoItem,
              promoItems: _vm.promoItems,
              promoItemGroup: _vm.promoItem
                ? _vm.promoItem.promo_item_group
                : null,
              item_sources: _vm.item_sources,
              vendorIds: _vm.vendorIds,
              readOnlyPromo: _vm.readOnlyPromo,
              promo: _vm.promo
            },
            on: {
              close: _vm.closeRelatedItemsModal,
              cancel: function($event) {
                _vm.showRelatedItemsModal = false
              }
            },
            model: {
              value: _vm.showRelatedItemsModal,
              callback: function($$v) {
                _vm.showRelatedItemsModal = $$v
              },
              expression: "showRelatedItemsModal"
            }
          })
        : _vm._e(),
      _vm.deleteModalData.deleting
        ? _c("DeleteItemGroupModal", {
            attrs: { groupInfo: _vm.deleteModalData },
            on: {
              close: function($event) {
                _vm.deleteModalData.deleting = false
              },
              update: _vm.deletedPromoItemGroup
            }
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.applyAllDialog, width: "450" },
          on: { "click:outside": _vm.closeApplyAllDialog }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Confirm Apply All ")]),
              _c("v-card-text", [_vm._v(" " + _vm._s(_vm.applyAllText) + " ")]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey darken-1", text: "" },
                      on: { click: _vm.closeApplyAllDialog }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        loading: _vm.applyingAll,
                        disabled: _vm.applyingAll
                      },
                      on: { click: _vm.applyAll }
                    },
                    [_vm._v(" Confirm ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }