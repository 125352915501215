import axios from 'axios'
import qs from 'qs'
import AuthToken from '@/auth/auth-token'

class Promos {
  constructor () {
    this.headers = {
      'Content-type': 'application/json'
    }
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        ...this.headers
      }
    })
    this.promos = base
  }

  async delete (promoId) {
    await AuthToken.setAuth(this.promos)
    
    return this.promos({
      url: `/promo/${promoId}`,
      method: 'DELETE'
    })
  }
  
  async getList (params = {}) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: '/promos',
      method: 'GET',
      params: { ...params },
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async getPromosByIds (promo_ids = []) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: '/promos_by_ids',
      method: 'GET',
      params: { promo_ids },
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async search(data, offset = 0, limit = 250, args = {} ) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: '/promos/search_promos',
      method: 'POST',
      data,
      params: { offset, limit, ...args },
    })
  }

  async get (promoId) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/${promoId}`,
      method: 'GET'
    })
  }

  async post (promo) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo`,
      method: 'POST',
      data: promo
    })
  }

  async put (promoId, promo) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/${promoId}`,
      method: 'PUT',
      data: promo
    })
  }

  async clone (promoId, data) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/clone/${promoId}`,
      method: 'POST',
      data
    })
  }

  async shuttle (promoId, party_id, party_name, item_sources) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/shuttle/${promoId}`,
      method: 'POST',
      data: { party_id, party_name, item_sources }
    })
  }

  async suggestedPromoNumber () {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/suggested_promo_number`,
      method: 'GET'
    })
  }

  async download_csv(promoId) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/make_csv_promotion/${promoId}`,
      method: 'GET'
    })
  }

  async export(payload) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promos/export`,
      method: 'POST',
      data: payload
    })
  }

  async reindex (promoId, update_history = false) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/reindex/${promoId}`,
      method: 'POST',
      params: { update_history }
    })
  }

  async updateHistory (promoId) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/update_history/${promoId}`,
      method: 'PUT'
    })
  }

  async checkLinkedItems (promoId) {
    await AuthToken.setAuth(this.promos)
    return this.promos({
      url: `/promo/missing_linked_items/${promoId}`,
      method: 'GET'
    })
  }
}
export default new Promos()