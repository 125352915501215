export async function paginate (api, baseUrl, limit, offset, callback = null, check_allowances = false) {
  const data = []
  const generator = getPaginated(api, baseUrl, limit, offset, check_allowances)
  for await (const result of generator) {
    if (callback) {
      const results = await Promise.allSettled(result.map(r => callback(r)))
      data.push(...results)
    } else {
      data.push(...result)
    }
  }
  return { data }
}

async function* getPaginated (api, baseUrl, limit, offset, check_allowances) {
  let page = 1

  while (true) {
    if (page > 1) {
      offset = (page * limit) - limit
    }
    const res = await api({
      url: baseUrl,
      method: 'GET',
      params: { limit, offset, check_allowances }
    })

    if (!res.data || res.data.length === 0) {
      break
    }

    yield res.data

    if (res.data.length < limit) break
    page++
  }
}