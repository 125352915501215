import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class PromoItemGroup {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.promoItemGroup = base
  }
  
  handleSuccess (response) {
    return response
  }

  async get (promoItemGroupId) {
    await AuthToken.setAuth(this.promoItemGroup)

    return this.promoItemGroup({
      url: `/promo_item_group/${promoItemGroupId}`,
      method: 'GET'
    })
  }
  
  /** are these the same call */
  async getItemGroupsByPromo (promo_id) {
    await AuthToken.setAuth(this.promoItemGroup)
    return this.promoItemGroup({
      url: `/promo_item_groups/${promo_id}`,
      method: 'GET'
    })
  }

  async getItemGroup (promo_item_group_id) {
    await AuthToken.setAuth(this.promoItemGroup)
    return this.promoItemGroup({
      url: `/promo_item_groups/${promo_item_group_id}`,
      method: 'GET'
    })
  }

  async post (itemGroup) {
    await AuthToken.setAuth(this.promoItemGroup)
    
    return this.promoItemGroup({
      url: `/promo_item_group`,
      method: 'POST',
      data: itemGroup
    })
  }

  async put (promoItemGroupId, itemGroup) {
    await AuthToken.setAuth(this.promoItemGroup)
    
    return this.promoItemGroup({
      url: `/promo_item_group/${promoItemGroupId}`,
      method: 'PUT',
      data: itemGroup
    })
  }

  async deletePromoItemGroup (promoItemGroupId, itemGroup) {
    await AuthToken.setAuth(this.promoItemGroup)
    
    return this.promoItemGroup({
      url: `/promo_item_group/${promoItemGroupId}`,
      method: 'DELETE',
      data: itemGroup
    })
  }
}

export default new PromoItemGroup()