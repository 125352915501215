var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        top: "",
        "content-class": "pa-0",
        color: _vm.alertType,
        timeout: _vm.timeout
      },
      model: {
        value: _vm.alert,
        callback: function($$v) {
          _vm.alert = $$v
        },
        expression: "alert"
      }
    },
    [
      _c(
        "v-alert",
        {
          staticClass: "my-0",
          attrs: {
            dense: "",
            border: "left",
            dismissible: "",
            prominent: "",
            type: _vm.alertType,
            "retain-focus": true
          },
          model: {
            value: _vm.alert,
            callback: function($$v) {
              _vm.alert = $$v
            },
            expression: "alert"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.alertMessage) + " "),
          _vm._l(_vm.messages, function(message, i) {
            return _c("div", { key: i, staticClass: "errorItem" }, [
              _vm._v(" " + _vm._s(message) + " ")
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }