import PartyRelationship from '@/axios/party-relationship-endpoint'

export const utils = {
  methods: {
    async getAllSettled (promises, returnErrors = false) {
      const rejected = []
      const results = await Promise.allSettled(promises)
      const fulfilled = results.flatMap(result => {
        if (result.status === 'rejected') {
          rejected.push(result)
          return []
        }
        return result.value
      })
      if (rejected.length > 0) console.log('Rejected promises:', rejected)
      if (returnErrors) {
        return { fulfilled, rejected }
      }
      return fulfilled
    },
    calcNetUnitCost (cost, qty_per_case, sumScans, sumBillbacks) {
      let netUnitCost = ''

      if (cost != null && qty_per_case != null) {
        // remove formatting/normalize
        cost = this.getNumericPrice(cost)
        qty_per_case = Number(qty_per_case)

        if (cost > 0 && qty_per_case > 0 && (sumScans + sumBillbacks) >= 0) {
          netUnitCost = ((cost - sumBillbacks) / qty_per_case) - sumScans
          // if calculated value isn't a positive number, set to 0
          if (Math.sign(netUnitCost) !== 1) netUnitCost = 0
        }
        if (netUnitCost !== '' && !Number.isNaN(netUnitCost)) {
          netUnitCost = `$${this.$config.formatCurrency(netUnitCost)}`
        }
      }
      return netUnitCost
    },
    calcGrossProfit (promoItem, netUnitCost) {
      let grossProfit = ''
      netUnitCost = this.getNumericPrice(netUnitCost)
      const promoPrice = this.getNumericPrice(promoItem.promotion_price)

      if (promoPrice > 0 && netUnitCost > 0) {
        const amount = (promoPrice - netUnitCost) / promoPrice
        grossProfit = `${(amount * 100).toFixed(2)}%`
      }
      return grossProfit
    },
    calcEffectiveCost (item, promo) {
      let effectiveCost = ''
      effectiveCost = item.future_cost_date <= promo.end_dt ? item.future_cost : item.cost
      return effectiveCost
    },
    formatItemPrices (item, formatFields = ['sale_price', 'cost', 'effective_cost', 'future_cost']) {
      for (const field of formatFields) {
        item[field] = this.getFormattedPrice(item[field])
      }
      return item
    },
    formatCurrencyDisplay (value) {
      return parseFloat(value).toFixed(2)
    },
    getFormattedPrice (value) {
      return (value != null) ? `$${this.$config.formatCurrency(value)}` : value
    },
    getNumericPrice (value) {
      if (typeof value === 'string' && value.includes('$')) {
        value = value.replace('$', '')
      }
      value = Number(value)
      return Number.isNaN(value) ? 0 : value
    },
    getFormattedGroup (group, isAccrualPromo) {
      const priceFields = [
        'retail_price', 'promotion_price', 'rebate_amount',
        'variance', 'amap_allowance', 'billback_allowance'
      ]
      for (const field of priceFields) {
        const val = group[field]
        if (field === 'billback_allowance' && isAccrualPromo) {
          if (group.allowance_amt_type?.toLowerCase() !== 'case') continue
        }
        group[field] = this.$config.formatCurrency(val)
      }
      return group
    },
    sortByKey (arr, key) {
      arr.sort((a, b) => {
        if (a && b) {
          if (a[key] < b[key]) return -1
          else if (a[key] > b[key]) return 1
          else return 0
        }
      })
      return arr
    },
    async getMemberPartyIds (party, memberPartyTypes = []) {
      const partyIds = []
      const partyId = party.party_id || party.store_id
      const isRegionGroup = (party?.party_type === 'REGION_GROUP')
        || (party?.party_type_name?.toLowerCase() === 'region group')
      const relParty = isRegionGroup ? 'to_party' : 'from_party'

      const generator = PartyRelationship.genPartyRelationships(partyId, 0, 200, true)
      for await (const results of generator) {
        if (results?.length > 0) {
          const ids = results.flatMap(rel => {
            if (memberPartyTypes.includes(rel[relParty]?.party_type?.constant)) {
              return rel[relParty].id
            }
            return []
          })
          partyIds.push(...ids)
        }
      }
      if (this.limitAccessByRelatedStores && isRegionGroup) {
        return partyIds.filter(id => this.userRelatedPartyIds.includes(id))
      }
      return partyIds
    }
  }
}