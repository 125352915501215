import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'

class PromoItem {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.promoItem = base
  }
  
  async post (data) {
    await AuthToken.setAuth(this.promoItem)

    return this.promoItem({
      url: `/promo_item`,
      method: 'POST',
      data: data
    })
  }

  async put (promo_item_id, data) {
    await AuthToken.setAuth(this.promoItem)

    return this.promoItem({
      url: `/promo_item/${promo_item_id}`,
      method: 'PUT',
      data
    })
  }

  async getItemsByGroup (promo_item_group_id) {
    await AuthToken.setAuth(this.promoItem)
    return this.promoItem({
      url: `/promo_items/${promo_item_group_id}`,
      method: 'GET'
    })
  }

  async getItemsByPromo ({ promo_id, offset = 0, limit = 50, paging = false, callback = null, check_allowances = false }) {
    await AuthToken.setAuth(this.promoItem)

    if (paging) {
      const url = `/promo_items/promo/${promo_id}`
      return paginate(this.promoItem, url, limit, offset, callback, check_allowances)
    }
    return this.promoItem({
      url: `/promo_items/promo/${promo_id}`,
      method: 'GET',
      params: { offset, limit, check_allowances }
    })
  }

  async getItem (promo_item_id) {
    await AuthToken.setAuth(this.promoItem)
    return this.promoItem({
      url: `/promo_item/${promo_item_id}`,
      method: 'GET'
    })
  }

  async delete (promo_item_id) {
    await AuthToken.setAuth(this.promoItem)

    return this.promoItem({
      url: `/promo_item/${promo_item_id}`,
      method: 'DELETE'
    })
  }
}

export default new PromoItem()