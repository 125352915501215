import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class PartyCategoryRelationship {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.partyCategoryRelationship = base
  }

  async getRelationshipsByParty (party_id, offset = 0, limit = 50) {
    await AuthToken.setAuth(this.partyCategoryRelationship)

    return this.partyCategoryRelationship({
      url: `/party_category_relationship/categories/${party_id}`,
      method: 'GET',
      params: { offset, limit }
    })
  }

}

export default new PartyCategoryRelationship()
