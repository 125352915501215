var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$auth.isLoading || !_vm.$auth.isAuthenticated
    ? _c(
        "v-overlay",
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    : _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-app-bar",
            { attrs: { color: "main", dark: "", app: "", "clipped-left": "" } },
            [
              _c("v-app-bar-nav-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$auth.isAuthenticated,
                    expression: "$auth.isAuthenticated"
                  }
                ],
                staticClass: "mx-auto",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  }
                }
              }),
              _c(
                "router-link",
                { attrs: { to: { path: "/" } } },
                [
                  _vm.tenantsWithLogos.includes("" + _vm.$auth.tenant)
                    ? _c("v-img", {
                        staticClass: "ml-6",
                        attrs: {
                          "max-height": "110",
                          "max-width": "110",
                          src: require("@/assets/" +
                            _vm.$auth.tenant +
                            "_logo.png"),
                          alt: _vm.$auth.tenant + " Logo"
                        }
                      })
                    : _c(
                        "v-app-bar-nav-icon",
                        [
                          _c("v-icon", { attrs: { color: "#fff" } }, [
                            _vm._v("mdi-home")
                          ])
                        ],
                        1
                      )
                ],
                1
              ),
              _c("v-spacer"),
              _vm.tenant === "awg" ? _c("VideoMenu") : _vm._e(),
              _c(
                "v-col",
                { staticClass: "pr-0", attrs: { cols: "auto" } },
                [_c("PortalNavMenu", { attrs: { active: "promo" } })],
                1
              )
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              attrs: {
                clipped: "",
                color: "grey lighten-3",
                width: "200",
                app: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c("v-divider"),
                      _c(
                        "div",
                        { class: [!_vm.miniVariant ? "pa-2" : "pa-1"] },
                        [
                          _c(
                            "v-btn",
                            {
                              style: _vm.styleObject,
                              attrs: { block: "", id: "logoutBtn" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.logout()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "#fff" } }, [
                                _vm._v("mdi-logout")
                              ]),
                              !_vm.miniVariant
                                ? _c("span", [_vm._v("Logout")])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                { attrs: { "background-color": "#fff" } },
                _vm._l(_vm.navLinks, function(link, i) {
                  return _c(
                    "v-list-item",
                    { key: i, attrs: { to: { path: link.path }, link: "" } },
                    [
                      _c("v-icon", [_vm._v(_vm._s(link.icon))]),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", { staticClass: "ml-2" }, [
                            _vm._v(" " + _vm._s(link.text) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.maintenanceStatus
            ? _c("Maintenance")
            : _c(
                "v-main",
                { staticClass: "grey lighten-3" },
                [
                  _c("alertbar"),
                  _vm.hasPortalAccess
                    ? _c("router-view")
                    : _c(
                        "v-alert",
                        { attrs: { type: "error", text: "", border: "left" } },
                        [
                          _vm._v(
                            " You don't have authorized permissions to access this site. "
                          )
                        ]
                      )
                ],
                1
              ),
          _c(
            "v-footer",
            {
              staticClass: "justify-space-between",
              attrs: { color: "main", app: "" }
            },
            [
              _c("span", { staticClass: "white--text" }, [
                _vm._v("iPro "),
                _c("span", { staticClass: "registeredTrademark" }, [
                  _vm._v("®")
                ]),
                _vm._v(" Systems, LLC. © " + _vm._s(new Date().getFullYear()))
              ]),
              _c("span", { staticClass: "white--text" }, [
                _vm._v("v. " + _vm._s(_vm.version))
              ])
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }