var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-autocomplete", {
        attrs: {
          value: _vm.selectedSubvendors,
          items: _vm.subvendors,
          "item-text": "name",
          "item-value": "subvendor_party_id",
          label: "Subvendors",
          loading: _vm.loading,
          "search-input": _vm.subvendorSearch,
          "background-color": "input",
          placeholder: _vm.isAdmin ? "Start typing to search" : null,
          "hide-no-data": "",
          "hide-details": "",
          multiple: "",
          "cache-items": "",
          "return-object": "",
          "small-chips": "",
          "deletable-chips": "",
          dense: "",
          outlined: ""
        },
        on: {
          "update:searchInput": function($event) {
            _vm.subvendorSearch = $event
          },
          "update:search-input": function($event) {
            _vm.subvendorSearch = $event
          },
          change: function($event) {
            return _vm.$emit("update:selectedSubvendors", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              var attrs = ref.attrs
              var on = ref.on
              return [
                _c(
                  "v-list-item",
                  _vm._g(
                    _vm._b(
                      { attrs: { color: "primary" } },
                      "v-list-item",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ]),
                        _c("v-list-item-subtitle", [
                          _vm._v(" " + _vm._s(item.party_type_name) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }