import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class PromoAllowance {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.promoAllowance = base
  }

  
  async getPromoAllowances (promo_id, offset = 0, limit = 200) {
    await AuthToken.setAuth(this.promoAllowance)
    return this.promoAllowance({
      url: `/promo_allowances/${promo_id}/`,
      method: 'GET',
      params: { offset, limit }
    })
  }

  async post (data) {
    await AuthToken.setAuth(this.promoAllowance)
    return this.promoAllowance({
      url: `/promo_allowance/`,
      method: 'POST',
      data
    })
  }

  async put (data) {
    await AuthToken.setAuth(this.promoAllowance)
    return this.promoAllowance({
      url: `/promo_allowance/`,
      method: 'PUT',
      data
    })
  }
}

export default new PromoAllowance()