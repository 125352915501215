import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class PromoCategory {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.promoCategory = base
  }

  async getList (offset = 0, limit = 50) {
    await AuthToken.setAuth(this.promoCategory)

    return this.promoCategory({
      url: `/promo_categories`,
      method: 'GET',
      params: {
        offset,
        limit
      }
    })
  }

}

export default new PromoCategory()

