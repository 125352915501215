var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-row",
            { staticClass: "promoRow" },
            [
              _c(
                "v-col",
                { staticClass: "px-1", attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Promotion Name",
                      autofocus: !_vm.existingPromo,
                      rules: _vm.promoNameRules,
                      readonly: _vm.readOnlyPromo,
                      "background-color": "#fff",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.promo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.promo, "name", $$v)
                      },
                      expression: "promo.name"
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.currentFields, function(field, i) {
                return _c(
                  "v-col",
                  {
                    key: i,
                    staticClass: "px-1",
                    attrs: { cols: _vm.currentFields.length > 3 ? null : 2 }
                  },
                  [
                    field.isPercent
                      ? _c("v-text-field", {
                          attrs: {
                            label: field.name,
                            type: "number",
                            min: "0",
                            step: "1",
                            suffix: "%",
                            rules: [_vm.isValidPercent],
                            disabled: _vm.readOnlyPromo,
                            "hide-details": "auto",
                            "background-color": "#fff",
                            dense: "",
                            autocomplete: "off",
                            outlined: ""
                          },
                          model: {
                            value: _vm.promo["" + field.value],
                            callback: function($$v) {
                              _vm.$set(_vm.promo, "" + field.value, $$v)
                            },
                            expression: "promo[`${field.value}`]"
                          }
                        })
                      : _c("v-text-field", {
                          attrs: {
                            label: field.name,
                            type: "number",
                            min: "0",
                            step: ".01",
                            prefix: "$",
                            rules:
                              field.value === "promotion_price"
                                ? _vm.promoPriceRules
                                : _vm.getPriceRules(field),
                            disabled:
                              _vm.readOnlyPromo ||
                              (field.value === "variance"
                                ? _vm.isVarianceDisabled
                                : false),
                            error: _vm.showErrorState(field, _vm.promo),
                            "hide-details": "auto",
                            "background-color": "#fff",
                            dense: "",
                            autocomplete: "off",
                            outlined: ""
                          },
                          model: {
                            value: _vm.promo["" + field.value],
                            callback: function($$v) {
                              _vm.$set(_vm.promo, "" + field.value, $$v)
                            },
                            expression: "promo[`${field.value}`]"
                          }
                        })
                  ],
                  1
                )
              }),
              _vm.showAccrualAllowance
                ? _c(
                    "v-col",
                    { staticClass: "px-1", attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Accrual Allowance",
                          type: "number",
                          min: "0",
                          step: ".01",
                          prefix: "$",
                          disabled: _vm.readOnlyPromo,
                          "hide-details": "auto",
                          "background-color": "#fff",
                          dense: "",
                          autocomplete: "off",
                          outlined: ""
                        },
                        model: {
                          value: _vm.promo["accrual_allowance"],
                          callback: function($$v) {
                            _vm.$set(_vm.promo, "accrual_allowance", $$v)
                          },
                          expression: "promo['accrual_allowance']"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tenant === "pricechopper"
                ? _c(
                    "v-col",
                    { staticClass: "px-1", attrs: { cols: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          label: "Category Manager",
                          items: _vm.categoryManagers,
                          "item-text": "name",
                          "item-value": "id",
                          clearable: "",
                          disabled: _vm.readOnlyPromo,
                          "hide-details": "auto",
                          "background-color": "#fff",
                          dense: "",
                          autocomplete: "off",
                          outlined: "",
                          "hide-no-data": ""
                        },
                        model: {
                          value: _vm.promo["category_manager_id"],
                          callback: function($$v) {
                            _vm.$set(_vm.promo, "category_manager_id", $$v)
                          },
                          expression: "promo['category_manager_id']"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }